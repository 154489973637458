









































import {
  AbstractBreadcrumbsItem,
  DashmixBreadcrumbsProps,
  DashmixTheme,
  TableRowAction,
  TableRowElement
} from '@movecloser/ui-core'
import { Component } from 'vue-property-decorator'
import { ICollection } from '@movecloser/front-core'

import { DropdownActions } from '../../shared/contracts/content'
import { HeaderInterface } from '../../shared/components'
import { Identifier, Inject, logger } from '../../../backoffice'
import { InteractiveTable } from '../../shared/components/InteractiveTable'
import { ModelListHeader } from '../../shared/components/ModelListHeader'
import { Query } from '../../shared/contracts/query'

import { ISiteResolver, SiteResolverType } from '../../root/services/site-resolver'

import { AbstractList } from './AbstractList'
import { ContentModals } from '../config/modals'
import { ContentModel, ContentStatus, IPublishContentPayload } from '../contracts'
import { createBreadcrumbsFromContent } from '../helpers'
import {
  ContentActions,
  collectionTableHead,
  nodesTableHead,
  nodeRowActionsFactory
} from '../maps/listing'
import { TableCollectionRow } from './TableCollectionRow.vue'
import { TableNodeRow } from './TableNodeRow.vue'
import { TableParentRow } from './TableParentRow.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<ListWithNodes>({
  name: 'ListWithNodes',
  components: { InteractiveTable, ModelListHeader, TableParentRow }
})
export class ListWithNodes extends AbstractList {
  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  public breadcrumbsItems: AbstractBreadcrumbsItem[] = []
  public parentRow: TableRowElement = {} as TableRowElement

  public get actions (): DropdownActions {
    return {
      [ContentActions.Preview]: {
        callback: (data: unknown) => {
          this.onPreview(data as ContentModel)
        }
      },

      [ContentActions.Edit]: {
        callback: (data: unknown) => {
          const model = data as ContentModel
          return this.edit(model.id)
        }
      },

      [ContentActions.Publish]: {
        callback: (data: unknown, payload: IPublishContentPayload) => {
          const model = data as ContentModel
          return this.onPublish(model.id, payload)
        },
        confirmation: {
          header: 'actions.publish.header',
          contentText: 'actions.publish.contentText',
          buttonLabel: 'atoms.publish'
        }
      },

      [ContentActions.Delete]: {
        callback: (data: unknown) => {
          const model = data as ContentModel
          return this.onDelete(model.id)
        },
        confirmation: {
          header: 'actions.delete.header',
          contentText: 'actions.delete.contentText',
          theme: DashmixTheme.Danger,
          buttonLabel: 'atoms.delete'
        }
      },

      [ContentActions.Sort]: {
        callback: () => {
          return this.onSortContent()
        }
      },

      [ContentActions.Clone]: {
        callback: (data: unknown) => {
          const model = data as ContentModel
          return this.onClone(model.id)
        },
        confirmation: {
          header: 'actions.clone.header',
          contentText: 'actions.clone.contentText',
          buttonLabel: 'atoms.clone'
        }
      }
    }
  }

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: this.breadcrumbsItems.splice(1),
      root: this.breadcrumbsItems[0]
    }
  }

  public get header (): HeaderInterface {
    return {
      title: this.listHeader.title || '',
      buttonLabel: this.listHeader.addLabel || undefined,
      linkTarget: this.listHeader.addTarget
    }
  }

  public get rowActions (): TableRowAction[] {
    return nodeRowActionsFactory(this.domain, this.user)
  }

  public get rowComponent () {
    return this.isSearching ? TableCollectionRow : TableNodeRow
  }

  public get tableHead () {
    return this.isSearching ? collectionTableHead : nodesTableHead
  }

  public onSortContent (): void {
    this.modalConnector.open(ContentModals.SortContent, {
      collection: this.tableData.map(r => r.data),
      parent: this.parentRow.data,
      onConfirm: (children: Identifier[]) => {
        this.sort(children, () => {
          this.modalConnector.close()
        })
      },
      onClose: () => this.modalConnector.close()
    }, { className: 'sort-content-modal__modal' })
  }

  protected async loadList (query: Query): Promise<void> {
    let parentId: Identifier = 0
    if (this.$route.query.parent) {
      parentId = this.$route.query.parent as unknown as Identifier
    } else {
      const siteModel = this.siteResolver.getSite()

      if (siteModel) {
        parentId = siteModel.rootContent
      }
    }

    const types: string[] = [this.contentType, ...this.contentListConfig.loadWith]
    const promise: Promise<ICollection<ContentModel> | ContentModel> = this.isSearching
      ? this.contentRepository.loadCollection(types, { q: this.queryParams.q })
      : this.contentRepository.loadNode(types, parentId, query)

    try {
      const data: ICollection<ContentModel> | ContentModel = await promise

      this.tableData = [
        ...(Array.isArray(data) ? data : (data.children || []))
      ].map(model => {
        return {
          id: `${model.id}`,
          selectable: this.canEditContents || this.canDeleteContents,
          data: model
        }
      })

      this.itemsTotal = Array.isArray(data)
        ? (data.meta.total ? data.meta.total : 0)
        : (data.childrenCount ? data.childrenCount : 0)

      this.breadcrumbsItems = []
      if (!Array.isArray(data)) {
        this.breadcrumbsItems = createBreadcrumbsFromContent(data, 'content.list', 'parent')

        this.parentRow = { id: `${data.id}`, selectable: false, data: data }
      }
    } catch (e) {
      logger(e, 'error')
    }
  }

  protected edit (id: Identifier): void {
    this.$router.push({
      name: 'content.edit.content',
      params: { id: `${id}`, type: 'page' }
    })
  }

  protected async onDelete (id: Identifier): Promise<void> {
    try {
      await this.contentRepository.delete(id)

      this.tableData = this.tableData.filter(rowData => rowData.id !== id.toString())
      --this.itemsTotal
    } catch (e) {
      logger(e, 'error')
    }
  }

  protected async onClone (id: Identifier): Promise<void> {
    try {
      const newId = await this.contentRepository.clone(id)
      this.edit(newId)
    } catch (e) {
      logger(e, 'error')
    }
  }

  protected async onPreview (data: ContentModel): Promise<void> {
    if (!data || !data.url || !data.variants) {
      return
    }

    const activeVariants = data.variants.filter((variant) => variant.status === ContentStatus.Published)
    if (!activeVariants.length || activeVariants.length < 0) {
      return
    }

    try {
      const url: string = await this.variantsRepository.preview(
        data.id as unknown as Identifier,
        activeVariants[0].id as unknown as number
      )

      window.open(url)
    } catch (e) {
      logger(e, 'warn')
    }
  }

  protected async onPublish (id: Identifier, payload: IPublishContentPayload): Promise<void> {
    return await this.contentRepository.publish(id, payload)
  }

  protected async sort (children: Identifier[], callback: () => void): Promise<void> {
    let parentId: Identifier = 0

    if (this.$route.query.parent) {
      parentId = this.$route.query.parent as unknown as Identifier
    } else {
      const siteModel = this.siteResolver.getSite()

      if (siteModel) {
        parentId = siteModel.rootContent
      }
    }

    try {
      await this.contentRepository.changeOrder(
        parentId,
        children
      )

      await this.loadList(this.queryParams)
      if (callback) {
        callback()
      }
    } catch (e) {
      logger(e, 'error')
    }
  }
}

export default ListWithNodes
