



















































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { DashmixAlertTheme, DashmixIconName } from '@movecloser/ui-core'
import {
  EventbusType,
  IEventbus,
  IModal,
  ModalType,
  ResourceActionFailed
} from '@movecloser/front-core'
import { MetaInfo } from 'vue-meta'

import { ConnectorErrors } from '../../shared/exceptions/connector-errors'
import { EditModeLayout, EditModeTabsProps } from '../../shared/components'
import { Identifier, Inject, IRelatedService, logger, RelatedServiceType } from '../../../backoffice'

import { IUserRepository, UserRepositoryType } from '../../users/contracts/repositories'

import {
  AdditionalVariantStatus,
  IVariantStatusService,
  VariantStatus,
  VariantStatusServiceType
} from '../services/variantStatus'
import { ContentModals } from '../config/modals'
import {
  ContentModel,
  ContentRepositoryType,
  ContentStatus,
  IContentRepository,
  IVariant,
  IVariantsRepository,
  SaveVariantEvent,
  SaveVariantMode,
  SimpleVariantModel,
  VariantData,
  VariantErrors,
  VariantModel,
  VariantsCounts,
  VariantsErrors,
  VariantsRepositoryType,
  VariantsServices
} from '../contracts'

import {
  ContentPermissions,
  ContentTypeValidator,
  IContentPermissions,
  IContentTypeAware
} from '../mixins'

import {
  createBreadcrumbsFromContent,
  initBreadcrumbs,
  resolveVariantsToLoad,
  resolveVariantToFocus
} from '../helpers'

import { Variant } from '../components/Variant.vue'
import { Variant as ModelVariant } from '../models/variant'
import { VariantTab } from '../components/VariantTab.vue'
import { VariantActions } from '../components/VariantActions.vue'
import { VariantAddons } from '../components/VariantAddons.vue'
import { VariantTabActions } from '../components/VariantTabActions.vue'
import { VariantsSelection } from '../components/VariantsSelection.vue'
import ComponentOptions from '../../../modules/partials/ComponentOptions/ComponentOptions.mixin.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<EditContent>({
  name: 'EditContent',
  components: {
    EditModeLayout,
    Variant,
    VariantActions,
    VariantAddons,
    VariantsSelection,
    VariantTab,
    VariantTabActions
  },
  metaInfo (this: EditContent): MetaInfo {
    return {
      title: `${this.$t('content.editTabs.content')}`
    }
  },

  beforeDestroy (): void {
    this.variantsStatusService.unsubscribe()
  }
})
export class EditContent extends Mixins<IContentPermissions, IContentTypeAware, ComponentOptions>(
  ContentPermissions,
  ContentTypeValidator,
  ComponentOptions
) {
  @Inject(ContentRepositoryType)
  protected contentsRepository!: IContentRepository

  @Inject(EventbusType)
  protected eventBus!: IEventbus

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(VariantsRepositoryType)
  protected variantsRepository!: IVariantsRepository

  @Inject(UserRepositoryType)
  protected usersRepository!: IUserRepository

  @Inject(VariantStatusServiceType)
  protected variantsStatusService!: IVariantStatusService

  public activeVariantTab: string = ''
  public breadcrumbs = initBreadcrumbs
  protected errors: VariantsErrors = { code: null, global: null, variant: {} }
  protected isDirty: boolean = false
  public isDisabled: boolean = false
  public isGeneratingPreview: boolean = false
  public isLoading: boolean = true
  public selectedVariants: VariantModel[] = []
  public variants: SimpleVariantModel[] = []
  public variantsCount: VariantsCounts = {}
  protected variantsServices: VariantsServices = {}

  public icons = DashmixIconName

  public get activeVariant (): VariantModel {
    return this.selectedVariants.find(v => `${v.id}` === this.activeVariantTab) as VariantModel
  }

  public set activeVariant (toChange: VariantModel) {
    this.onVariantChange(toChange)
  }

  public get alertTheme (): DashmixAlertTheme {
    return this.errors.code === `${ConnectorErrors.ServerError}`
      ? DashmixAlertTheme.Danger : DashmixAlertTheme.Warning
  }

  public config: any = this.getComponentOptions(
    { redirectToListAfterSave: true }
  )

  public get contentId (): Identifier {
    return this.$route.params.id
  }

  public get modeTabs (): EditModeTabsProps {
    return {
      items: [
        {
          label: 'content.editTabs.content',
          route: {
            name: 'content.edit.content',
            params: { id: this.contentId.toString(), type: this.contentType }
          }
        },
        {
          label: 'content.editTabs.meta',
          route: {
            name: 'content.edit.basics',
            params: { id: this.contentId.toString(), type: this.contentType }
          },
          guard: (id: Identifier): boolean => {
            return this.preventLosingData(id)
          }
        }
      ],
      initTab: 0
    }
  }

  public get tabs () {
    return this.selectedVariants.map(v => {
      return {
        component: Variant,
        props: {
          authUser: this.user,
          contentType: this.contentType,
          errors: this.errors,
          onDirty: this.toggleDirty,
          onVariantChange: this.onVariantChange,
          relatedService: this.resolveVariantServices(v.id),
          variant: v
        },
        tab: {
          id: `${v.id}`,
          label: VariantTab,
          props: {
            active: this.activeVariant.id,
            authUser: this.user,
            onVariantChange: this.onVariantChange,
            variant: v
          }
        }
      }
    })
  }

  public clearGlobalError (): void {
    this.errors.global = null
  }

  public getActiveVariantErrors (): VariantErrors {
    return this.errors.variant[`${this.activeVariant.id}`] || {}
  }

  public previewVariant (): void {
    this.isGeneratingPreview = true

    if (this.isDirty) {
      this.modalConnector.open(ContentModals.VariantPreviewDecision, {
        cancel: () => (this.isGeneratingPreview = false),
        generate: () => this.generatePreview(),
        saveAndGenerate: () => this.generatePreview(true)
      }, { closableWithOutsideClick: false })
    } else {
      this.generatePreview()
    }
  }

  public resolveVariantServices (variant: Identifier): IRelatedService {
    if (!(variant in this.variantsServices)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.variantsServices[variant] = this.$container!.get<IRelatedService>(RelatedServiceType)
    }

    return this.variantsServices[variant]
  }

  public async saveVariant (event: SaveVariantEvent): Promise<void> {
    this.isDisabled = true

    try {
      await this.variantsRepository.update(
        this.$route.params.id,
        this.activeVariant.id,
        this.activeVariant.toUpdatePayload(),
        event.postSave
      )

      this.isDirty = false

      if (event.postSave !== SaveVariantMode.None) {
        if (this.config.redirectToListAfterSave) {
          await this.$router.push({
            name: 'content.list',
            params: { type: this.contentType }
          })
        } else {
          const variant = this.activeVariant
          variant.set('status', ContentStatus.Published)

          this.activeVariant = ModelVariant.hydrate<VariantData, IVariant>(variant.toObject())
        }
      }
    } catch (e) {
      if (!(e instanceof ResourceActionFailed)) {
        this.errors.global = (e as Error).message
        return
      }

      if (
        [ConnectorErrors.Conflict, ConnectorErrors.Validation].includes(e.status as ConnectorErrors)
      ) {
        this.errors.global = e.message

        if (e.payload) {
          this.errors.variant[this.activeVariant.id.toString()] = e.payload
        }
      }
    }

    this.isDisabled = false
  }

  public async unpublishVariant (): Promise<void> {
    this.isDisabled = true

    try {
      await this.variantsRepository.unpublish(this.$route.params.id, this.activeVariant.id)

      this.isDirty = false

      await this.$router.push({ name: 'content.list', params: { type: this.contentType } })
    } catch (e) {
      if (!(e instanceof ResourceActionFailed)) {
        this.errors.global = (e as Error).message
        return
      }

      if (
        [ConnectorErrors.Conflict, ConnectorErrors.Validation].includes(e.status as ConnectorErrors)
      ) {
        this.errors.global = e.message

        if (e.payload) {
          this.errors.variant[`${this.activeVariant.id}`] = e.payload
        }
      }
    }

    this.isDisabled = false
  }

  protected async changeVariantEditor (editorId: Identifier): Promise<void> {
    this.isLoading = true
    this.variantsStatusService.unsubscribe()

    try {
      const editor = await this.usersRepository.load(editorId)
      const variant = this.activeVariant
      variant.set('editor', editor)

      this.activeVariant = ModelVariant.hydrate<VariantData, IVariant>(variant.toObject())
      this.modalConnector.close()
    } catch (e) {
      logger(e, 'warn')
    }

    this.isLoading = false
  }

  protected async generatePreview (withSave: boolean = false): Promise<void> {
    try {
      const url: string = withSave
        ? await this.variantsRepository.updateAndPreview(
          this.$route.params.id,
          this.activeVariant.id,
          this.activeVariant.toUpdatePayload()
        )
        : await this.variantsRepository.preview(
          this.$route.params.id,
          this.activeVariant.id
        )

      window.open(url)
    } catch (e) {
      if (e instanceof ResourceActionFailed) {
        this.errors.code = `${e.status}`
      } else {
        this.errors.code = ConnectorErrors.Unknown
      }

      this.errors.global = e instanceof Error ? e.message : ''
      logger(e, 'error')
    }

    this.isGeneratingPreview = false
  }

  protected async loadContentWithBaseVariants (id: Identifier): Promise<void> {
    this.isLoading = true

    try {
      const model: ContentModel = await this.contentsRepository.load(id)
      model.set('id', this.$route.params.id)

      this.breadcrumbs.items = [
        {
          label: this.$tc(this.contentTypeManager.getLabel(this.contentType), 2).toString(),
          target: { name: 'content.list', params: { type: this.contentType } }
        },
        ...createBreadcrumbsFromContent(
          model,
          'content.list',
          'parent'
        )
      ]

      if (model.variants) {
        // TODO: Remove when fixed addons type.
        this.variants = model.variants.map(v => {
          v.set('addons', typeof v.addons === 'string' ? JSON.parse(v.addons) : v.addons)
          return v
        })
        this.variantsCount = model.variantsCount
      }

      const promises: Promise<number | void>[] = []
      resolveVariantsToLoad(this.variants, this.user).map(v => {
        promises.push(
          this.variantsRepository.load(model.id, v.id)
            .then(variant => this.selectedVariants.push(variant))
            .catch(error => logger(error, 'error'))
        )
      })

      await Promise.all(promises)

      this.activeVariantTab = resolveVariantToFocus(this.selectedVariants, this.user)
      this.isLoading = false
    } catch (e) {
      logger(e, 'error')
    }
  }

  protected async lockVariant (): Promise<void> {
    this.isLoading = true

    try {
      await this.variantsRepository.lock(this.contentId, this.activeVariant.id)
      this.modalConnector.close()
    } catch (e) {
      logger(e, 'warn')
    }

    this.isLoading = false
  }

  @Watch('activeVariant', { deep: true })
  protected onActiveVariantChange (activeVariant: VariantModel, oldVariant: VariantModel): void {
    // Checking that active variant and user exist
    if (!activeVariant || !this.user) {
      return
    } else if (!oldVariant || activeVariant.id === oldVariant.id) {
      const { id } = this.activeVariant
      if (
        !this.variantsStatusService.hasActiveSubscription(
          id,
          this.activeVariant.editor?.id as Identifier
        ) &&
        activeVariant.isEditable(this.user.id)
      ) {
        // If active variant exist and interval is not set - set interval and return
        this.variantsStatusService.subscribeStatus(
          id,
          this.onStatusChange,
          this.activeVariant.editor?.id as Identifier
        )
        return
      }

      return
    }

    this.variantsStatusService.unsubscribe()

    if (activeVariant.isEditable(this.user.id)) {
      this.variantsStatusService.subscribeStatus(
        this.activeVariant.id,
        this.onStatusChange,
        this.activeVariant.editor?.id as Identifier
      )
    }
  }

  protected onLosingAccept (id: Identifier): void {
    this.eventBus.emit('ui:edit-mode.force', id)
    this.modalConnector.close()
  }

  protected async onPositiveTypeValidation (): Promise<void> {
    await this.loadContentWithBaseVariants(this.$route.params.id)
  }

  @Watch('selectedVariants', { deep: true })
  protected onSelectedVariantsEmpty (selectedVariants: VariantModel[]) {
    if (selectedVariants.length > 0) {
      return
    }

    this.loadContentWithBaseVariants(this.$route.params.id as unknown as Identifier)
  }

  protected async onStatusChange (status: VariantStatus, editorId?: Identifier): Promise<void> {
    switch (status) {
      case AdditionalVariantStatus.Deleted:
        await this.$router.push({ name: `content.${this.contentType}s.list` })
        break
      case AdditionalVariantStatus.Locked:
        await this.lockVariant()
        break
      case AdditionalVariantStatus.EditorChange:
        if (!editorId) {
          throw new Error('[EditContent], [editorId] must be provided when editorChange')
        }

        await this.changeVariantEditor(editorId)
        break
      case ContentStatus.InAcceptance:
        await this.$router.push({
          name: 'content.acceptance.show',
          params: { contentType: this.contentType, id: this.activeVariant.id.toString() }
        })
        break
      case ContentStatus.Archived:
      case ContentStatus.Accepted:
      case ContentStatus.Published:
      case ContentStatus.Rejected:
      case ContentStatus.Draft:
      case ContentStatus.Unpublished:
        await this.loadContentWithBaseVariants(this.$route.params.id)
    }
  }

  protected async onTypeValidationFail (defaultType: string): Promise<void> {
    await this.$router.push({
      name: 'content.edit.content', params: { id: this.contentId.toString(), type: defaultType }
    })
  }

  protected onVariantChange (changed: VariantModel): void {
    // let hasChanged: boolean = false TODO: Remove reference to use this check.
    const list: VariantModel[] = [...this.selectedVariants]
    for (const index in this.selectedVariants) {
      if (list[index].id === changed.id) {
        // TODO: Remove reference to use this check.
        // hasChanged = JSON.stringify(changed.toObject()) !== JSON.stringify(list[index].toObject())
        list[index] = changed
        this.selectedVariants = list
        break
      }
    }

    this.toggleDirty(true)
  }

  protected preventLosingData (id: Identifier): boolean {
    if (!this.isDirty) {
      return true
    }

    this.modalConnector.open(ContentModals.PreventLoosingData, {
      onConfirm: () => this.onLosingAccept(id),
      onClose: () => this.modalConnector.close()
    })

    return false
  }

  protected toggleDirty (isDirty: boolean): void {
    this.isDirty = isDirty
  }
}

export default EditContent
