




























import {
  AbstractBreadcrumbsItem,
  DashmixBreadcrumbsProps,
  DashmixTheme,
  TableRowAction
} from '@movecloser/ui-core'
import { Component } from 'vue-property-decorator'
import { ICollection } from '@movecloser/front-core'

import { DropdownActions } from '../../shared/contracts/content'
import { HeaderInterface } from '../../shared/components'
import { Identifier, logger } from '../../../backoffice'
import { InteractiveTable } from '../../shared/components/InteractiveTable'
import { ModelListHeader } from '../../shared/components/ModelListHeader'
import { Query } from '../../shared/contracts/query'

import { AbstractList } from './AbstractList'
import { ContentModel, ContentStatus, IPublishContentPayload } from '../contracts'
import { collectionTableHead, ContentActions, nodeRowActionsFactory } from '../maps/listing'
import { TableCollectionRow } from './TableCollectionRow.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'ListCollection',
  components: { InteractiveTable, ModelListHeader }
})
export class ListCollection extends AbstractList {
  public breadcrumbsItems: AbstractBreadcrumbsItem[] = []
  public rowComponent = TableCollectionRow
  public totalPages: number = 0

  public get actions (): DropdownActions {
    return {
      [ContentActions.Preview]: {
        callback: (data: unknown) => {
          this.onPreview(data as ContentModel)
        }
      },
      [ContentActions.Edit]: {
        callback: (data: unknown) => {
          const model = data as ContentModel
          return this.edit(model.id)
        }
      },

      [ContentActions.Publish]: {
        callback: (data: unknown, payload: IPublishContentPayload) => {
          const model = data as ContentModel
          return this.onPublish(model.id, payload)
        },
        confirmation: {
          header: 'actions.publish.header',
          contentText: 'actions.publish.contentText',
          buttonLabel: 'atoms.publish'
        }
      },

      [ContentActions.Delete]: {
        callback: (data: unknown) => {
          const model = data as ContentModel
          return this.onDelete(model.id)
        },
        confirmation: {
          header: 'actions.delete.header',
          contentText: 'actions.delete.contentText',
          theme: DashmixTheme.Danger,
          buttonLabel: 'atoms.delete'
        }
      },

      [ContentActions.Clone]: {
        callback: (data: unknown) => {
          const model = data as ContentModel
          return this.onClone(model.id)
        },
        confirmation: {
          header: 'actions.clone.header',
          contentText: 'actions.clone.contentText',
          buttonLabel: 'atoms.clone'
        }
      }
    }
  }

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: this.breadcrumbsItems.splice(1),
      root: this.breadcrumbsItems[0]
    }
  }

  public get header (): HeaderInterface {
    return {
      title: this.listHeader.title || '',
      buttonLabel: this.listHeader.addLabel || undefined,
      linkTarget: this.listHeader.addTarget
    }
  }

  public get rowActions (): TableRowAction[] {
    return nodeRowActionsFactory(this.domain, this.user)
  }

  public get tableHead () {
    return collectionTableHead
  }

  protected async loadList (query: Query): Promise<void> {
    const types: string[] = [this.contentType, ...this.contentListConfig.loadWith]

    try {
      const collection: ICollection<ContentModel> = await this.contentRepository.loadCollection(
        types,
        query
      )

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: this.canEditContents || this.canDeleteContents,
          data: model
        }
      })

      this.itemsTotal = collection.meta.total
      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
    } catch (e) {
      logger(e, 'error')
    }
  }

  protected edit (id: Identifier): void {
    this.$router.push({
      name: 'content.edit.content',
      params: { id: `${id}`, type: 'page' }
    })
  }

  protected async onDelete (id: Identifier): Promise<void> {
    try {
      await this.contentRepository.delete(id)

      this.tableData = this.tableData.filter(rowData => rowData.id !== id.toString())
      --this.itemsTotal
    } catch (e) {
      logger(e, 'error')
    }
  }

  protected async onClone (id: Identifier): Promise<void> {
    try {
      const newId = await this.contentRepository.clone(id)
      this.edit(newId)
    } catch (e) {
      logger(e, 'error')
    }
  }

  protected async onPreview (data: ContentModel): Promise<void> {
    if (!data || !data.url || !data.variants) {
      return
    }

    const activeVariants = data.variants.filter((variant) => variant.status === ContentStatus.Published)
    if (!activeVariants.length || activeVariants.length < 0) {
      return
    }

    try {
      const url: string = await this.variantsRepository.preview(
        data.id as unknown as Identifier,
        activeVariants[0].id as unknown as number
      )

      window.open(url)
    } catch (e) {
      logger(e, 'warn')
    }
  }

  protected async onPublish (id: Identifier, payload: IPublishContentPayload): Promise<void> {
    return await this.contentRepository.publish(id, payload)
  }
}

export default ListCollection
