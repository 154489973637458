





































































































































































































import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName, SizeMap } from '@movecloser/ui-core'

import { AbstractModal } from '../../../shared/components/Modal'
import { FormColorPicker } from '../../../shared/components/form/ColorPicker'

import { ProductCategoryAddonData, ProductCategoryModalPayload } from '../../contracts/addons'
import { Inject, PossibleRelatedPicker, Related } from '../../../../backoffice'
import { mediaTypes } from '../../../media/contracts'
import { IModal, ModalType } from '@movecloser/front-core'
import { ProductGalleryItem } from '../ProductGalleryItem.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<ProductCategoryAddonModal>({
  name: 'ProductCategoryAddonModal',
  components: { FormColorPicker, ProductGalleryItem },
  created () {
    const {
      infoBarEntries,
      logo,
      banner,
      specificationColors,
      defaultImages,
      loyalty
    } = this.payload.addonData
    this.infoBarEntries = infoBarEntries
    this.logo = logo
    this.banner = banner
    this.specificationColors = specificationColors
    this.defaultImages = { ...this.defaultImages, ...defaultImages }

    if (loyalty) {
      this.loyalty = loyalty
    }
  }
})
export class ProductCategoryAddonModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  public payload!: ProductCategoryModalPayload

  public readonly allColors: string[] = [
    'background', 'backgroundBox', 'boxSectionContentTitle',
    'content', 'heading', 'navTabs', 'subHeading', 'stepSectionBoxShadow',
    'stepSectionStepBackground', 'stepSectionStepColor'
  ]

  public infoBarEntries: ProductCategoryAddonData['infoBarEntries'] = []

  public isShowLogoButton: boolean = false
  public isShowBannerButton: boolean = false
  public isShowHeroCoverButton: boolean = false
  public isShowHeroVideoButton: boolean = false
  public isShowStepImageButton: boolean = false
  public isShowColumnImageButton: boolean = false

  public logo: ProductCategoryAddonData['logo'] = null
  public banner: ProductCategoryAddonData['banner'] = null
  public specificationColors: ProductCategoryAddonData['specificationColors'] = {} as ProductCategoryAddonData['specificationColors']
  public defaultImages: ProductCategoryAddonData['defaultImages'] = {
    heroSection: {
      cover: { type: 'file', value: 64 },
      wide: false,
      video: null
    },
    columnSection: {
      image: null
    },
    stepSection: {
      image: null
    }
  }

  // FIXME: Loyalty programs should be dynamic for each individual project
  public loyaltyOptions = [{ label: 'Friends and family', value: 'faf' }]
  public loyalty: ProductCategoryAddonData['loyalty'] = []

  public readonly dashmixIconName = DashmixIconName

  @Inject(ModalType)
  private modalConnector!: IModal

  public addEntry (): void {
    this.infoBarEntries.push({
      // TODO: Fix the id.
      id: (Math.random() * 9999),
      text: '',
      description: ''
    })
  }

  public applyChanges (): void {
    this.payload.onChange({
      infoBarEntries: this.infoBarEntries,
      logo: this.logo,
      banner: this.banner,
      specificationColors: this.specificationColors,
      defaultImages: this.defaultImages,
      loyalty: this.loyalty
    })

    this.close()
  }

  protected async addHeroImg (data: Related<[]>[]) {
    this.payload.addonData.defaultImages = {
      ...this.defaultImages,
      heroSection: {
        ...this.defaultImages.heroSection,
        cover: data
      }
    }
  }

  protected async addHeroVideo (data: Related<[]>[]) {
    this.payload.addonData.defaultImages = {
      ...this.defaultImages,
      heroSection: {
        ...this.defaultImages.heroSection,
        video: data
      }
    }
  }

  protected async addColumnSectionImg (data: Related<[]>[]) {
    this.payload.addonData.defaultImages = {
      ...this.defaultImages,
      columnSection: {
        ...this.defaultImages.columnSection,
        image: data
      }
    }
  }

  protected async addLogo (data: Related) {
    this.payload.addonData.logo = data
  }

  protected async addBanner (data: Related) {
    this.payload.addonData.banner = data
  }

  protected async addStepSectionImg (data: Related<[]>[]) {
    this.payload.addonData.defaultImages = {
      ...this.defaultImages,
      stepSection: {
        ...this.defaultImages.stepSection,
        image: data
      }
    }
  }

  protected async deleteHeroCoverItem () {
    this.defaultImages.heroSection.cover = null
    this.defaultImages = {
      ...this.defaultImages,
      heroSection: {
        ...this.defaultImages.heroSection,
        cover: null
      }
    }
  }

  protected async deleteHeroVideoItem () {
    this.defaultImages.heroSection.video = null
    this.defaultImages = {
      ...this.defaultImages,
      heroSection: {
        ...this.defaultImages.heroSection,
        video: null
      }
    }
  }

  protected async deleteStepSectionImage () {
    this.defaultImages.stepSection.image = null
    this.defaultImages = {
      ...this.defaultImages,
      stepSection: {
        ...this.defaultImages.stepSection,
        image: null
      }
    }
  }

  protected async deleteColumnSectionImage () {
    this.defaultImages.columnSection.image = null
    this.defaultImages = {
      ...this.defaultImages,
      columnSection: {
        ...this.defaultImages.columnSection,
        image: null
      }
    }
  }

  protected async deleteLogo () {
    this.logo = null
  }

  protected async deleteBanner () {
    this.banner = null
  }

  public get icon (): DashmixIconName | undefined {
    return DashmixIconName.PlusSolid
  }

  public removeEntry (id: number): void {
    this.infoBarEntries = this.infoBarEntries.filter((infoBarEntry) => infoBarEntry.id !== id)
  }

  public showHeroCoverButton (show: boolean): void {
    this.isShowHeroCoverButton = show
  }

  public showHeroVideoButton (show: boolean): void {
    this.isShowHeroVideoButton = show
  }

  public showStepImageButton (show: boolean): void {
    this.isShowStepImageButton = show
  }

  public showColumnImageButton (show: boolean): void {
    this.isShowColumnImageButton = show
  }

  public showLogoButton (show: boolean): void {
    this.isShowLogoButton = show
  }

  public showBannerButton (show: boolean): void {
    this.isShowBannerButton = show
  }

  public openFilePicker<T = Related<[]>[]> (
    callback: (data: T, index?: number) => void,
    isImage: boolean,
    index?: number
  ) {
    this.$emit('swap', {
      toOpen: PossibleRelatedPicker.File,
      payload: {
        multiple: false,
        onSelection: (data: T) => callback(data, index),
        onClose: () => {
          this.modalConnector.close()
        },
        config: {
          allowedMediaType: isImage ? mediaTypes.Image : mediaTypes.Video
        }
      },
      config: { size: SizeMap.XLarge }
    })
  }
}

export default ProductCategoryAddonModal
