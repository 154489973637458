





















import { Component } from 'vue-property-decorator'
import { debounce, DebouncedFunc } from 'lodash'

import { Identifier, Inject } from '../../../../backoffice'
import { DictionaryRepositoryType, IDictionaryRepository } from '../../../root/contracts/repositories'
import { CheckItem } from '../../../shared/contracts/form'

import { AbstractAddon } from './AbstractAddon'
import { AddonErrors } from './AddonErrors.vue'

@Component({
  name: 'CategoryAddon',
  components: { AddonErrors }
})
export class CategoryAddon extends AbstractAddon {
  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  public activeCategories: Identifier[] = []
  public isLoading = true
  public categoriesDictionary: CheckItem[] = [] as CheckItem[]

  mounted () {
    this.getVariantProperty()

    this.isLoading = true

    this.dictRepository.loadCategoriesDictionary({}).then(dict => {
      this.categoriesDictionary = [...dict].map(model => {
        return {
          key: model.id,
          label: model.name
        }
      })
    }).finally(() => {
      this.isLoading = false
    })
  }

  public getValue (itemKey: Identifier) {
    return this.activeCategories.includes(itemKey)
  }

  public setCategoryValue (event: boolean, itemKey: Identifier) {
    if (event) {
      this.activeCategories.push(itemKey)
    } else {
      this.activeCategories = this.activeCategories.filter(category => itemKey !== category)
    }

    this.onInputValueChange(this.activeCategories)
  }

  protected getVariantProperty (): void {
    const categoriesFromModel = this.variant.getProperty<Identifier[]>('categories')

    if (!categoriesFromModel) {
      this.activeCategories = []
      return
    }

    this.activeCategories = categoriesFromModel
  }

  protected onInputValueChange: DebouncedFunc<(activeCategories: Identifier[]) => void> =
    debounce((activeCategories: Identifier[]) => {
      this.onInputValueChange.cancel()
      this.variant.setProperty<Identifier[]>('categories', activeCategories)

      this.onChange(this.variant)
    }, 500)
}

export default CategoryAddon
