// Copyright © 2021 Move Closer

import { PossibleRelatedType } from '@core'

import { Permission } from '@module/auth/contracts/permissions'
import { NavigationItem } from '@component/Navigation/Navigation.contracts'

export interface DefaultRoute {
  path?: string
  meta: { permissions: Permission[] }
  name: string
}

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export const defaultRoutesConfig: DefaultRoute[] = [
  // TODO dashboard in this place
  {
    path: '/media/list',
    name: 'media.list',
    meta: { permissions: [Permission.ViewMedia] }
  },
  {
    path: '/users',
    name: 'users.list',
    meta: {
      permissions: [Permission.ViewUsers]
    }
  },
  {
    path: '/users/roles',
    name: 'users.roles.list',
    meta: {
      permissions: [Permission.ViewRoles]
    }
  },
  {
    path: '/authors',
    name: 'users.authors.list',
    meta: {
      permissions: [Permission.ViewAuthors]
    }
  }
]

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const navigation: NavigationItem[] = [
  {
    icon: 'fa fa-fw fa-tachometer-alt',
    label: 'Dashboard',
    route: { name: 'root.dashboard' },
    permissions: [
      Permission.ViewContents
    ]
  },
  {
    icon: 'fa fa-camera',
    label: 'Media',
    children: [
      {
        label: 'Katalog Plików',
        route: { name: 'media.list' },
        permissions: [
          Permission.ViewMedia
        ]
      },
      {
        label: 'Pliki oczekujące',
        route: { name: 'media.waiting-list' },
        permissions: [
          Permission.ViewMedia
        ]
      },
      {
        label: 'Galerie',
        route: { name: 'media.gallery.list' },
        permissions: [
          Permission.ViewMedia
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-project-diagram',
    label: 'Strony',
    children: [
      {
        label: 'Dodaj nową',
        route: { name: 'content.create', params: { type: 'page' } },
        permissions: [
          Permission.CreateContents
        ]
      },
      {
        label: 'Przeglądaj strony',
        route: { name: 'content.list', params: { type: 'page' } },
        permissions: [
          Permission.ViewContents
        ]
      },
      {
        label: 'Do akceptacji',
        route: { name: 'content.acceptance.list', params: { type: 'page' } },
        permissions: [
          Permission.AcceptContents
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-newspaper',
    label: 'Blog',
    children: [
      {
        label: 'Dodaj wpis',
        route: { name: 'content.create', params: { type: 'article' } },
        permissions: [
          Permission.CreateContents
        ]
      },
      {
        label: 'Przeglądaj wpisy',
        route: { name: 'content.list', params: { type: 'article' } },
        permissions: [
          Permission.ViewContents
        ]
      },
      {
        label: 'Do akceptacji',
        route: { name: 'content.acceptance.list', params: { type: 'article' } },
        permissions: [
          Permission.AcceptContents
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-shopping-cart',
    label: 'Produkty',
    route: { name: 'products.list' },
    permissions: [
      Permission.ViewProducts
    ]
  },
  {
    icon: 'fa fa-2x fa-folder-open',
    label: 'Listy',
    children: [
      {
        label: 'Listy artykułowe',
        route: { name: 'sets.list', params: { type: PossibleRelatedType.Articles } },
        permissions: [
          // Permission.ViewArticleSet
        ]
      },
      {
        label: 'Listy produktów',
        route: { name: 'sets.list', params: { type: PossibleRelatedType.Products } },
        permissions: [
          // Permission.ViewProductsSet
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-users',
    label: 'Użytkownicy',
    children: [
      {
        label: 'Lista użytkowników',
        route: { name: 'users.list' },
        permissions: [
          Permission.ViewUsers
        ]
      },
      {
        label: 'Zarządzanie rolami',
        route: { name: 'users.roles.list' },
        permissions: [
          Permission.ViewRoles
        ]
      }
      // {
      //   label: 'Lista autorów',
      //   route: { name: 'users.authors.list' },
      //   permissions: [
      //     // todo autorzy
      //     // Permission.ViewAuthors
      //   ]
      // }
    ]
  },
  {
    icon: 'fa fa-fw fa-cogs',
    label: 'Ustawienia',
    children: [
      {
        label: 'Settings',
        route: { name: 'settings.config.list' },
        permissions: [
          Permission.ViewSettings
        ]
      },
      {
        label: 'Nawigacja',
        route: { name: 'settings.navigation' },
        permissions: [
          Permission.ViewNavigation
        ]
      },
      {
        label: 'Bannery',
        route: { name: 'settings.config.edit', params: { type: 'banners' } },
        permissions: [
          Permission.ViewSettings
        ]
      },
      {
        label: 'Fragmenty',
        route: { name: 'content.list', params: { type: 'snippet' } },
        permissions: [
          Permission.ViewLayout
        ]
      },
      {
        label: 'Layouty',
        route: { name: 'layouts.layouts.list' },
        permissions: [
          Permission.ViewLayout
        ]
      }
    ]
  }
]
