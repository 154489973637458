// Copyright © 2021 Move Closer

import clone from 'lodash/clone'
import { ComponentObjectPropsOptions, DashmixSelectItem } from '@movecloser/ui-core'
import { computed, onMounted, PropType, ref, SetupContext, toRef, watch } from '@vue/composition-api'

import {
  IVariantsRepository,
  ModuleValidationResult,
  VariantModel,
  VariantsRepositoryType
} from '../../../../content/contracts'

import { ContainerModule, IRelatedService, logger, ModuleConstructor, resolve } from '../../../../../backoffice'

import { ContainerDrawerProps, UseContainerFormProvides } from '../PageBuilder.contracts'
import { useModuleDependencies } from './shared.hooks'
import { pageBuilderContainerTypes } from '../PageBuilder.config'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const containerDrawerProps: ComponentObjectPropsOptions<ContainerDrawerProps> = {
  relatedService: {
    type: Object as PropType<IRelatedService>,
    required: true
  },
  source: {
    type: Object as PropType<ContainerModule>,
    required: true
  },
  variant: {
    type: Object as PropType<VariantModel>,
    required: false,
    default: () => {
      return {}
    }
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function useContainerDrawer (props: ContainerDrawerProps, ctx: SetupContext): UseContainerFormProvides {
  const source = toRef(props, 'source')
  const container = ref<ContainerModule | null>(null)

  const { pickRelated } = useModuleDependencies()
  const variantRepository = resolve<IVariantsRepository>(VariantsRepositoryType)

  const isContainerCreated = computed<boolean>(() => {
    return typeof source.value.type !== 'string' || source.value.type.length > 0
  })

  const isOpen = computed<boolean>(() => {
    return container.value !== null
  })

  const errors = ref<string[]>([])
  const validating = ref<boolean>(false)

  const selectedForm = computed<ModuleConstructor | string>(() => {
    return ctx.root.$options.configuration?.byKey('builder.containerForm') || 'div'
  })

  const type = computed<string>({
    get: () => {
      if (container.value === null || typeof props.source.type !== 'string') {
        return 'solid'
      }

      return container.value.type as string
    },
    set: (value: string) => {
      if (container.value === null) {
        return
      }

      const clonedContainer = container.value
      clonedContainer.type = value

      container.value = clonedContainer
    }
  })

  const typeOptions = computed<DashmixSelectItem[]>(() => {
    return pageBuilderContainerTypes.map(t => ({
      label: ctx.root.$t(`builder.containerTypes.${t}`).toString(),
      value: t
    }))
  })

  function apply (): void {
    ctx.emit('apply', container.value)
    ctx.emit('close')
    container.value = null
  }

  /**
   * Apply all changes that were placed.
   */
  function applyChanges (): void {
    if (!container.value) {
      logger('[ContainerDrawer] Module.value is `null`', 'error')
      return
    }

    validating.value = true

    variantRepository.validate(container.value, 'container').then((result: ModuleValidationResult) => {
      if (result.isValid) {
        apply()
      }

      errors.value = result.errors
    }).catch(error => {
      logger(error, 'warn')
    }).finally(() => {
      validating.value = false
    })
  }

  onMounted(() => {
    container.value = clone(props.source)
  })

  watch(type, (value: string) => {
    if (container.value === null || value === 'snippet') {
      return
    }

    const clonedContainer = { ...container.value }
    clonedContainer.source = undefined

    container.value = clonedContainer
  })

  return {
    applyChanges,
    container,
    errors,
    isContainerCreated,
    isOpen,
    pickRelated,
    type,
    typeOptions,
    validating,
    selectedForm
  }
}
