// Copyright © 2022 Move Closer

import { StaticRouteDefinition } from './contexts'

import { authModuleStaticRoutes } from './front/auth/routes'
import { checkoutModuleStaticRoutes } from './front/checkout/routes'
import { ordersModuleStaticRoutes } from './front/orders/routes'
import { productsModuleStaticRoutes } from './front/products/routes'
import { profileModuleStaticRoutes } from './front/profile/routes'
import { rootModuleStaticRoutes } from './front/root/routes'
import { benefitsModuleStaticRoutes } from './front/loyalty/routes'
import { wishlistModuleStaticRoutes } from './front/wishlist/routes'

export * from './contexts'
export { CustomDSL } from './dsl/registry'
export * from './front/shared'
// export * as localesPL from './locales/pl'
export * from './modules/allowed'
export * from './modules/composer'
export * from './modules/partials/IconSelect'
export * from './modules/partials/ModuleSettings'
export * from './modules/registry.forms'
export * from './modules/registry.ui'
export * from './support'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl >
 */
export const PossibleStaticRoutes: StaticRouteDefinition[] = [
  ...authModuleStaticRoutes,
  ...benefitsModuleStaticRoutes,
  ...checkoutModuleStaticRoutes,
  ...ordersModuleStaticRoutes,
  ...productsModuleStaticRoutes,
  ...profileModuleStaticRoutes,
  ...rootModuleStaticRoutes,
  ...wishlistModuleStaticRoutes
]
