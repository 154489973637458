













































import { Component, PropSync } from 'vue-property-decorator'

import { FormInput } from '../../shared/components/form'

import { ConfigForm } from './_abstract/ConfigForm.vue'
import { ProductRecommendationsSettings } from '../contracts'
import {
  RecommendationSourceModes
} from '../contracts/productsRecommendationsForm/config'
import { DashmixSelectItem } from '@movecloser/ui-core'
import {
  RecommendationsForm
} from '../contracts/productsRecommendationsForm/contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ProductRecommendationsForm>({
  name: 'ProductRecommendationsForm',
  components: { FormInput },
  created () {
    this.composeForm()
  }
})
export class ProductRecommendationsForm extends ConfigForm {
  @PropSync('formData', { type: Object, required: true })
  public payload!: ProductRecommendationsSettings

  public composedFormData: Array<RecommendationsForm> | null = null

  /**
   * Returns a collection of available recommendation modes
   */
  public get allowedRecommendationModes (): Array<DashmixSelectItem> {
    const temp = []
    for (const key of Object.values(RecommendationSourceModes)) {
      temp.push({
        label: this.$t(`settings.form.productRecommendations.initialData.sourceModes.${key}`).toString(),
        value: key
      })
    }
    return temp
  }

  public onSubmit () {
    this.$emit('submit', { reproject: true, global: false })
  }

  public onModelUpdate (val: string, data: RecommendationsForm) {
    if (!data.customData) {
      if (val === RecommendationSourceModes.Synerise) {
        this.payload.automation = {
          ...this.payload.automation,
          [data.syncedKey]: {
            type: val,
            value: {
              generic: null,
              premium: null
            }
          }
        }
      } else {
        this.payload.automation = {
          ...this.payload.automation,
          [data.syncedKey]: {
            type: val
          }
        }
      }
    } else {
      if (val === RecommendationSourceModes.Synerise) {
        this.payload.automation = {
          ...this.payload.automation,
          [data.syncedKey]: {
            type: val,
            value: data.customData
          }
        }
      } else {
        this.payload.automation = {
          ...this.payload.automation,
          [data.syncedKey]: {
            type: val
          }
        }
      }
    }
  }

  /**
   * Composes forms to render
   * @protected
   */
  protected composeForm (): void {
    this.composedFormData = Object.entries(this.payload.automation).map(([key, value]) => {
      return {
        name: `recommendations-item-${key}`,
        id: `recommendations-item-${key}`,
        syncedKey: key,
        label: this.$t(`settings.form.productRecommendations.initialData.options.${key}.label`).toString(),
        placeholder: this.$t(`settings.form.productRecommendations.initialData.options.${key}.placeholder`).toString(),
        customData: value.value
      }
    })
  }
}

export default ProductRecommendationsForm
