
















































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme } from '@movecloser/ui-core'
import { IModal, ModalType } from '@movecloser/front-core'
import { Inject } from '../../../backoffice'
import { SlickItem, SlickList } from 'vue-slicksort'
import { v4 as uuid } from 'uuid'

import { Modals } from '../../shared/config/modals'

import { NavigationItem, OpenStrategy } from '../contracts'

@Component({
  name: 'NavigationList',
  components: {
    NavigationListItem: () => import('./NavigationListItem.vue'),
    SlickList,
    SlickItem
  }
})
export class NavigationList extends Vue {
  @PropSync('links', { type: Array, required: true })
  public linksList!: NavigationItem[]

  @Prop({ type: Boolean, required: false, default: false })
  public disabled?: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public fullWidth!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public openOnStart!: boolean

  @Inject(ModalType)
  protected modalConnector!: IModal

  public Icons = DashmixIconName

  public id = 'navigation-list-' + uuid()

  public add () {
    const emptyItem: NavigationItem = {
      label: '',
      strategy: OpenStrategy.Self,
      target: null,
      children: []
    }

    const newList = [...this.linksList, emptyItem]
    this.linksList = newList

    this.$emit('edit', { item: emptyItem, path: [newList.length - 1] })
  }

  public addChild (i: number) {
    const links = [...this.linksList]
    const children = [...links[i].children ?? []]
    const emptyItem: NavigationItem = {
      label: '',
      strategy: OpenStrategy.Self,
      target: null,
      children: []
    }

    children.push(emptyItem)
    links[i] = { ...links[i], children }

    this.linksList = links
    this.$emit('edit', { item: emptyItem, path: [children.length - 1, i] })
  }

  public deleteItem (i: number) {
    this.modalConnector.open(Modals.Confirm, {
      content: {
        header: this.$t('settings.navigation.link.delete.confirmation.header'),
        contentText: this.$t('settings.navigation.link.delete.confirmation.text'),
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete',
        contentTitle: this.linksList[i].label
      },
      onConfirm: () => {
        this.doDeleteItem(i)
        this.modalConnector.close()
      }
    })
  }

  public doDeleteItem (i: number) {
    const links = [...this.linksList]
    links.splice(i, 1)

    this.linksList = links
  }

  public updateList (i: number, items: NavigationItem[]) {
    const links = [...this.linksList]
    links[i].children = items

    this.linksList = links
  }
}

export default NavigationList
