// Copyright © 2021 Move Closer

import { Injectable } from '@movecloser/front-core'

import { IToastService, ToastType } from './toaster.contracts'
import { INotyfOptions, Notyf } from 'notyf'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class ToastService implements IToastService<string, INotyfOptions> {
  private readonly config: INotyfOptions
  private readonly notify: Notyf

  constructor (config: INotyfOptions) {
    this.config = config
    this.notify = new Notyf({
      duration: 2000,
      position: {
        x: 'right',
        y: 'top'
      },
      types: [
        {
          type: 'success',
          className: 'notyf__toast--success',
          background: '#28A745FF',
          icon: {
            className: 'fas fa-check',
            tagName: 'i',
            color: '#fff'
          }
        },
        {
          type: 'warning',
          className: 'notyf__toast--warning',
          background: '#FFC107FF',
          icon: {
            className: 'fas fa-bell',
            tagName: 'i',
            color: '#fff'
          }
        },
        {
          type: 'info',
          className: 'notyf__toast--info',
          background: '#17A2B8FF',
          icon: {
            className: 'fas fa-info',
            tagName: 'i',
            color: '#fff'
          }
        },
        {
          type: 'error',
          className: 'notyf__toast--error',
          background: '#DC3545FF',
          duration: 2000,
          dismissible: true,
          icon: {
            className: 'fas fa-exclamation-triangle',
            tagName: 'i',
            color: '#fff'
          }
        }
      ]
    })
  }

  // type: string;
  // className: string;
  // duration: number;
  // icon: string | INotyfIcon | false;
  // /**
  //  * @deprecated Use `background` instead. Removal target: v4.0.0.
  //  */
  // backgroundColor: string;
  // background: string;
  // message: string;
  // ripple: boolean;
  // position: INotyfPosition;
  // dismissible: boolean;
  /**
   * Display error toast.
   */
  public error (content: string, title: string = '', options?: INotyfOptions): void {
    this.notify.error({
      message: (title) ? `${title}: ${content}` : content,
      ...this.composeOptions(options)
    })
  }

  /**
   * Display info toast.
   */
  public info (content: string, title: string = '', options?: INotyfOptions): void {
    this.notify.open({
      type: 'info',
      message: (title) ? `${title}: ${content}` : content,
      ...this.composeOptions(options)
    })
  }

  /**
   * Display any toast type.
   */
  public push (content: string, type: ToastType, title: string = '', options?: INotyfOptions): void {
    this.notify.open({
      type: type,
      message: (title) ? `${title}: ${content}` : content,
      ...options
    })
  }

  /**
   * Display success toast.
   */
  public success (content: string, title: string = '', options?: INotyfOptions): void {
    this.notify.success({
      message: (title) ? `${title}: ${content}` : content,
      ...this.composeOptions(options)
    })
  }

  /**
   * Display warning toast.
   */
  public warning (content: string, title: string = '', options?: INotyfOptions): void {
    this.notify.open({
      type: 'warning',
      message: (title) ? `${title}: ${content}` : content,
      ...this.composeOptions(options)
    })
  }

  /**
   * Compose options by merging given with default.
   */
  protected composeOptions (options?: INotyfOptions): INotyfOptions {
    if (!options) {
      return this.config
    }

    return { ...this.config, ...options }
  }
}
