










































































































































































import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'
import merge from 'lodash/merge'

import {
  DescriptionOfImage,
  IRelatedService,
  isRelated,
  PickerCallback,
  Related
} from '../../../contexts'

import { FormFieldset } from '../../partials/FormFieldset'
import { ImageForm, UnresolvedImage } from '../../partials/ImageForm'
import { MapSelector } from '../../partials/MapSelector'
import { RelatedPartial } from '../../partials/RelatedPartial'
import ComponentOptions from '../../partials/ComponentOptions/ComponentOptions.mixin.vue'

import {
  AllowedBackgroundColor,
  AllowedContainerColumnGap,
  AllowedContainerMargin,
  AllowedContainerPadding,
  AllowedContainerRowGap,
  AllowedContainerWidth, AllowedContainerZIndex,
  AllowedImagePosition,
  ContainerContentInput, ContainerOptions
} from '../Container.contracts'

import { createContainerContent } from '../Container.factory'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<ContainerForm>({
  name: 'ContainerForm',
  components: { FormFieldset, ImageForm, MapSelector },
  created (): void {
    this.setInitialContent()
  },
  mounted () {
    this.isImgPositionPredefined()
    if (isRelated(this._content.background.image)) {
      this.resolveRelated()
    }
  }
})
export class ContainerForm extends Mixins<RelatedPartial<DescriptionOfImage>, ComponentOptions>(
  RelatedPartial,
  ComponentOptions
) {
  @PropSync('content', { type: Object, required: true })
  public _content!: ContainerContentInput

  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  /**
   * Determines component config.
   */
  public config: ContainerOptions = this.getComponentOptions(
    {
      backgroundVariants: ['default'],
      backgroundColorPalette: AllowedBackgroundColor
    }
  )

  public initialContent = createContainerContent()

  public readonly ContainerColumnGap = AllowedContainerColumnGap
  public readonly ContainerMargin = AllowedContainerMargin
  public readonly ContainerZIndex = AllowedContainerZIndex
  public readonly ContainerPadding = AllowedContainerPadding
  public readonly ContainerRowGap = AllowedContainerRowGap
  public readonly ContainerWidth = AllowedContainerWidth
  public readonly ImagePosition = AllowedImagePosition

  public hasPredefinedImgPosition = true

  public get backgroundVariantsOptions (): DashmixSelectItem[] {
    const backgroundVariants: any = {}
    this.config.backgroundVariants.forEach((backgroundVariant: string) => {
      backgroundVariants[backgroundVariant] = backgroundVariant
    })
    return backgroundVariants
  }

  public get hasImage (): boolean {
    return !(!this._content.background.image || !isRelated(this._content.background.image))
  }

  public get image (): UnresolvedImage | null {
    if (!this._content.background.image) {
      return null
    }

    return {
      image: this._content.background.image
    }
  }

  public set image (image: UnresolvedImage | null) {
    const contentCopy = { ...this._content }

    if (!image || !image.image) {
      delete contentCopy.background.image
    } else {
      contentCopy.background.image = image.image
    }

    this._content = contentCopy

    this.$nextTick(() => {
      this.resolveRelated()
    })
  }

  /**
   * @inheritDoc
   */
  protected getRelatedSource (): Related | undefined {
    if (this._content === null || this._content.background.image === null ||
      typeof this._content.background.image === 'undefined') {
      return undefined
    }

    return this._content.background.image
  }

  protected isImgPositionPredefined (): void {
    if (!this._content.background.imagePosition) {
      return
    }

    const predefinedList = Object.values(AllowedImagePosition)

    this.hasPredefinedImgPosition = predefinedList.includes(
      (this._content.background.imagePosition as AllowedImagePosition)
    )
  }

  /**
   * Merges `this.initialContent` with the current value of `this._content`.
   */
  protected setInitialContent (): void {
    this._content = { ...merge(this.initialContent, this._content) }
  }

  public sortBackgroundColorOptions (_: DashmixSelectItem, second: DashmixSelectItem): number {
    if (second.value !== AllowedBackgroundColor.None) {
      return -1
    }
    return 1
  }

  public sortMarginOptions (first: DashmixSelectItem, second: DashmixSelectItem): number {
    if (!first.value && !second.value) {
      return 0
    } else if (!first.value) {
      return -1
    } else if (!second.value) {
      return 1
    }

    if ((second.value as number) < 0 || (first.value as number) < 0) {
      return (second.value as number) - (first.value as number)
    }

    return (first.value as number) - (second.value as number)
  }
}

export default ContainerForm
