











































































































import { Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import { IFile, MediaType } from '@movecloser/ui-core'

import { DescriptionOfImage, PossibleRelatedPicker, Related } from '../../../contexts'

import { RelatedPartial } from '../RelatedPartial'
import { UnresolvedImage } from './ImageForm.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({ name: 'ImageForm' })
export class ImageForm extends RelatedPartial<DescriptionOfImage> {
  @PropSync('image', { type: Object, required: false, default: null })
  public _image!: UnresolvedImage | null

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isDynamic!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly simple!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly withDimensions!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly stackView!: boolean

  public get defaultMeta () {
    if (this.isDynamic) {
      const auto = this.$t('modulesForms.Image.auto').toString()

      return {
        alt: auto,
        author: auto,
        caption: auto,
        title: auto
      }
    }

    return this.resolved
  }

  public get isRelatedSet (): boolean {
    return this._image !== null && typeof this._image.image === 'object' && this._image.image !== null &&
    typeof this._image.image.value !== 'undefined'
  }

  public get preview (): IFile | null {
    if (!this.resolved) {
      return null
    }

    return {
      type: this.resolved.mime as MediaType,
      thumbnail: this.resolved.path ? process.env.VUE_APP_IMAGE_PATH + this.resolved.path : this.resolved.url
    }
  }

  public addRelatedSource (): void {
    this.pickRelated(PossibleRelatedPicker.File, this.setRelatedImage, this.getRelatedSource())
  }

  public clear (): void {
    if (!this._image) {
      return
    }

    const imageCopy = { ...this._image }

    imageCopy.image = null
    this.resolved = null

    this._image = imageCopy
  }

  protected getRelatedSource (): Related | undefined {
    if (this._image === null || this._image.image === null ||
      typeof this._image.image.value === 'undefined') {
      return undefined
    }

    return this._image.image
  }

  protected setRelatedImage (selected: Related): void {
    if (this._image === null) {
      this._image = { image: selected }
    } else {
      const imageCopy = {
        ...this._image
      }

      imageCopy.image = selected
      this._image = imageCopy
    }

    this.$nextTick(() => {
      this.resolveRelated()
    })
  }

  @Watch('_image', { deep: true })
  protected onImageChange (image: UnresolvedImage | null) {
    if (image === null || image.image === null) {
      this.resolved = null
      return
    }

    if (!this.resolved || image.image.value !== this.resolved.id) {
      this.resolveRelated()
    }
  }
}

export default ImageForm
