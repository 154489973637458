









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, DashmixSelectItem, DashmixTheme, Hint } from '@movecloser/ui-core'
import debounce from 'lodash/debounce'
import { ICollection, IModel, Intersected, ResourceActionFailed } from '@movecloser/front-core'

import { DictData } from '../../root/contracts/models'
import { FormCheckbox } from '../../shared/components/form'
import { FormSelect } from '../../shared/components/form/Select'
import { FormTypeahead } from '../../shared/components/FromTypeahead/FormTypeahead'
import { Inject, PossibleRelatedType, SetType } from '../../../backoffice'
import { Loader } from '../../shared/components/Loader/Loader'

import { DictionaryRepositoryType, IDictionaryRepository } from '../../root/contracts/repositories'

import { SetSource } from '../contracts'
import { SourceType } from '../contracts/data'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<SetSourceForm>({
  name: 'SetSourceForm',
  components: {
    FormTypeahead,
    Loader,
    FormSelect,
    FormCheckbox
  },
  mounted (): void {
    if (this.source) {
      this.loadOption()
    }
  }
})
export class SetSourceForm extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public readonly disabled!: boolean

  @Prop({
    type: String,
    required: true
  })
  public readonly formName!: string

  @Prop({
    type: Number,
    required: true
  })
  public readonly index!: number

  @Prop({
    type: String,
    required: true
  })
  public readonly mode!: string

  @Prop({
    type: String,
    required: true
  })
  public readonly setType!: SetType

  @Prop({
    type: Object,
    required: true
  })
  public readonly source!: SetSource

  @Inject(DictionaryRepositoryType)
  private dictionaryRepository!: IDictionaryRepository

  public isLoading: boolean = false
  public sourceLabel: string | null = null
  public dictOptions: Hint[] = []

  private readonly sourceAll: Hint = { value: '0', label: 'Wszystkie' }

  // Helpers
  public buttonTheme = DashmixTheme
  public Icons = DashmixIconName

  public get getTypeaheadOption (): Hint[] {
    if (this.source.value !== '') {
      return [
        {
          value: this.source.value,
          label: this.source.label ?? this.sourceLabel ?? this.source.value
        }
      ]
    }
    return []
  }

  public get options () {
    switch (this.mode) {
      case SourceType.Tag:
      case SourceType.ArticleCategory:
        return this.dictOptions
      default:
        return []
    }
  }

  public get sourceLabels () {
    return {
      label: this.$t(`sets.create.form.${this.mode}.label`),
      placeholder: this.$t(`sets.create.form.${this.mode}.placeholder`)
    }
  }

  public get sourceTypeOptions (): DashmixSelectItem[] {
    switch (this.setType) {
      case PossibleRelatedType.Articles:
        return [
          {
            label: String(this.$t('sets.source.type.category')),
            value: SourceType.ArticleCategory
          },
          {
            label: String(this.$t('sets.source.type.tag')),
            value: SourceType.Tag
          }
        ]
      default:
        return []
    }
  }

  created () {
    if (this.setType === PossibleRelatedType.Articles && !this.source.type) {
      this.source.type = SourceType.ArticleCategory
    }

    this.$nextTick(() => {
      this.loadOptions()
    })
  }

  public loadDefaultOptions () {
    this.$nextTick(() => {
      this.loadOptions()
    })
  }

  public async loadOptions (searchParams?: string) {
    this.isLoading = true

    const logErrors = (error: ResourceActionFailed) => {
      console.log(error)
      this.isLoading = false
    }

    switch (this.mode) {
      case SourceType.ArticleCategory:
        await this.loadCategory(searchParams).catch(logErrors)
        break
      case SourceType.Tag:
        await this.loadTag(searchParams).catch(logErrors)
        break
    }
    this.isLoading = false
  }

  public async loadCategory (searchParams?: string) {
    this.isLoading = true
    this.dictOptions = [
      this.sourceAll,
      ...SetSourceForm.dictionaryToSelectOptions(
        await this.dictionaryRepository.loadCategoriesDictionary(
          {
            q: searchParams || '',
            sort: '+name'
          }
        )
      )
    ]
    this.isLoading = false
  }

  public async loadTag (searchParams?: string) {
    this.isLoading = true
    this.dictOptions = SetSourceForm.dictionaryToSelectOptions(
      await this.dictionaryRepository.loadTagsDictionary(
        {
          q: searchParams || '',
          sort: '+name'
        }
      )
    )
    this.isLoading = false
  }

  public onClear () {
    this.loadOptions()
  }

  public onDelete () {
    this.source.value = ''
    this.source.label = ''
  }

  public onSearch (searchedParams: string) {
    this.loadOptions(searchedParams)
  }

  public onSelect (selectedHint: Hint) {
    this.source.value = `${selectedHint.value}`
    this.source.label = selectedHint.label
  }

  protected deleteSource () {
    this.$emit('deleted', this.index)
  }

  protected async loadOption () {
    try {
      this.isLoading = true

      switch (this.mode) {
        case SourceType.ArticleCategory:
          if (this.source.value.toString() === '0') {
            this.sourceLabel = this.sourceAll.label
          } else {
            this.sourceLabel = (await this.dictionaryRepository.loadCategory(this.source.value))?.name
          }
          break
        case SourceType.Tag:
          this.sourceLabel = (await this.dictionaryRepository.loadTag(this.source.value))?.name
          break
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  @Watch('source', { deep: true })
  protected onSourceChange = debounce(() => {
    this.onSourceChange.cancel()

    this.$emit('changed', this.index, this.source)
  }, 500)

  @Watch('source.type', { deep: false })
  protected onSourceTypeChange (newType: string, oldType: string) {
    if (newType !== oldType) {
      this.source.value = ''
      this.source.label = ''
    }
  }

  private static dictionaryToSelectOptions (
    collection: ICollection<Intersected<IModel<DictData>, DictData>>
  ): Hint[] {
    return [...collection].map(
      (dictionary: Intersected<IModel<DictData>, DictData>) => {
        return {
          value: dictionary.id,
          label: dictionary.name
        }
      }
    )
  }
}

export default SetSourceForm
