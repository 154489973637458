// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

import { ownerAdapterMap } from '../../root/models/owner.adapter'

import { directoryAdapterMap } from '../models/directory.adapter'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export const fileAdapterMap: MappingConfig = {
  id: 'id',
  addedBy: {
    type: MappingTypes.Adapter,
    map: ownerAdapterMap,
    value: 'addedBy'
  },
  alt: 'alt',
  author: 'author',
  caption: 'caption',
  createdAt: 'createdAt',
  deletedAt: 'deletedAt',
  description: 'description',
  directory: {
    type: MappingTypes.Adapter,
    map: directoryAdapterMap,
    value: 'directory'
  },
  disk: 'disk',
  display: 'display',
  file: 'file',
  mime: 'mime',
  note: 'note',
  original: 'original',
  pending: 'pending',
  preset: 'preset',
  public: 'public',
  size: 'size',
  name: 'name',
  title: 'title',
  updatedAt: 'updatedAt',
  url: 'url'
}
