import { MagicModel, MappingConfig } from '@movecloser/front-core'

export interface ModelsConfig {
  useAdapter: boolean
  adapterMap?: MappingConfig
  model: MagicModel<any>
}

export enum SourceType {
  ArticleCategory = 'articleCategory',
  Tag = 'tag'
}
